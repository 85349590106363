// GLOBAL FUNCTIONS
// can be called from html like fenix.showHidden()
export function showHidden() {
    var x = document.getElementById("contentHidden");
    var buttonHidden = document.getElementById("hiddenButton");
    if (x.style.display === "none") {
        x.style.display = "block";
        buttonHidden.innerHTML = "Stäng";
        jQuery('html, body').animate({
            scrollTop: jQuery(x).offset().top - 140
        }, 1000);
    } else {
        x.style.display = "none";
        buttonHidden.innerHTML = "Lär dig mer";
        jQuery('html, body').animate({
            scrollTop: jQuery(buttonHidden).offset().top - 140
        }, 1000);
    }
}
export function showHiddenT() {
    var x = document.getElementById("contentHiddenT");
    var buttonHidden = document.getElementById("hiddenButtonT");
    if (x.style.display === "none") {
        x.style.display = "block";
        buttonHidden.innerHTML = "Stäng";
        jQuery('html, body').animate({
            scrollTop: jQuery(x).offset().top - 140
        }, 1000);
    } else {
        x.style.display = "none";
        buttonHidden.innerHTML = "Lär dig mer";
        jQuery('html, body').animate({
            scrollTop: jQuery(buttonHidden).offset().top - 140
        }, 1000);
    }
}
export function showHiddenF() {
    var x = document.getElementById("contentHiddenF");
    var buttonHidden = document.getElementById("hiddenButtonF");
    if (x.style.display === "none") {
        x.style.display = "block";
        buttonHidden.innerHTML = "Stäng";
        jQuery('html, body').animate({
            scrollTop: jQuery(x).offset().top - 140
        }, 1000);
    } else {
        x.style.display = "none";
        buttonHidden.innerHTML = "Lär dig mer";
        jQuery('html, body').animate({
            scrollTop: jQuery(buttonHidden).offset().top - 140
        }, 1000);
    }
}

export function showHiddenSeFler() {
    var x = document.getElementById("contentHiddenSeFler");
    var buttonHidden = document.getElementById("hiddenButtonSeFler");
    jQuery('.location-near__list ul li:nth-child(n+6)').toggle();
    jQuery('.location-near__list ul').toggleClass('expanded');
    if (x.style.display === "none") {
        x.style.display = "block";
        buttonHidden.innerHTML = "Stäng";
        jQuery('html, body').animate({
            scrollTop: jQuery('.location-near__items').offset().top + 150
        }, 1000);
    } else {
        x.style.display = "none";
        buttonHidden.innerHTML = "Se fler";
        jQuery('html, body').animate({
            scrollTop: jQuery('.location-near__items').offset().top + 150
        }, 1000);
    }
}
/**
 * Function getCitiesList(selectName) [refactored] - for SEO cleanup city selectors initially load empty or with one current city only
 * Used to fire Ajax to destroy empty selector initialized Choices and then to re-init it with the city names list
 * Called on city dropdown selector click for the first time:
 * Header, Body (same desktop and mob), Mobile: header mob nav, Mobile: sticky - this one doesn't exist in Start (Home), only in city pages
 * and inside Borgerlig begravning page
 * @param selectName The name of the particular selector that was clicked to open dropdown
 */
var firstChoicesClick = [];
firstChoicesClick["header_cities_drop"] = true;
firstChoicesClick["top_cities_drop"] = true;
firstChoicesClick["location_module_cities_drop"] = true;
firstChoicesClick["header_mob_cities_drop"] = true;
firstChoicesClick["menu_cities_drop"] = true;
firstChoicesClick["funeral_cities_drop"] = true;
firstChoicesClick["dual-card_cities_drop"] = true;

export function getCitiesList(selectName) {

    let loader = '<div class="search-autocomplete__loading"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>';

    if (firstChoicesClick[selectName]) {
        const thisSelect =  "#city-dropdown-" + selectName;
        let el_id = jQuery(thisSelect).attr('id');
        // console.log("thisSelect: " + thisSelect + " | el_id: " + el_id);
        window[selectName].destroy();
        jQuery(thisSelect).css('opacity', '0');
        jQuery.ajax({
            url: "/wp-content/themes/fenix4/pages/ajax-choice-drop.php",
            type: "post",
            data: {select_name: selectName},
            dataType: 'html',
            success: function (result) {
                jQuery(thisSelect).html(result);
                const el = document.getElementById(el_id);
                const choices = new Choices(el, {
                        placeholder: true,
                        silent: true,
		                placeholderValue: 'Skriv ditt område här',
		                searchPlaceholderValue: 'Skriv ditt område här',
                        itemSelectText: '',
                        noResultsText: jQuery(el).data('no-results'),
                        shouldSort: false,
                        position: "bottom",
                        searchResultLimit: 100,
                        searchFields: ['label'],
                        fuseOptions: {
                            includeMatches: true,
                            threshold: 0.0,
                            tokenize: true,
                        },
                        classNames: {
                            containerOuter: 'choices cities-list--choices',
	                        placeholder: 'choices__placeholder',
                        }
                    }
                ).showDropdown();
            }
        });
        firstChoicesClick[selectName] = false;
    }
}

// Den: Write value of the clicked location placeholder image to $_SESSION["barhus-placeholder-num"] and other types
export function sendPlaceholderNum(slug, typ, placeholderNum) {
    console.log("AJAX: typ: " + typ + " | placeholdernum: " + placeholderNum);
    jQuery.ajax({
        type: "POST",
        url: "/wp-content/themes/fenix4/php/ajax-set-session-var.php",
        data: {typ: typ, placeholdernum: placeholderNum},
        success: function (data) {
            window.location.href = "/plats/" + slug + "/";
            //window.location.href = "/plats/" + slug + "/?placeholdernum=" + placeholderNum;
        }
    });
}
